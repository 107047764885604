import React, { FC, useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { toast } from 'react-toastify';

import Header from 'components/Header';
import Footer from 'components/Footer';
import { ButtonMUI } from 'components/ButtonMUI';

import { useStore } from 'store';
import { SiteVariant } from 'utils';

import vrPoster from 'assets/images/vrPoster.png';
import somniumPoster from 'assets/images/somniumPoster.jpeg';

import somniumBanner from 'assets/images/somniumBanner.png';
import vrBanner from 'assets/images/vrBanner.png';
import welcomeBanner from 'assets/images/welcomeBanner.png';

import madeInIcon from 'assets/images/made_in.svg';

import styles from './styles.module.scss';

const mainVR = 'https://ai-space.fra1.cdn.digitaloceanspaces.com/website/main_video10kbitrate.mp4';
const mainHeadset = 'https://ai-space.fra1.cdn.digitaloceanspaces.com/website/Main_Page_VR1_Glam_V2.mp4';

export const AppContainer: FC = observer(({ children }) => {
  const { userStore, accountStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState<'left' | 'right' | null>(null);
  const [videoVisible, setVideoVisible] = useState({ left: false, right: false });
  const [showSelection, setShowSelection] = useState(true);

  const videoRefs = {
    left: useRef<HTMLVideoElement>(null),
    right: useRef<HTMLVideoElement>(null),
  };

  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 1080;

  useEffect(() => {
    if (userStore.isAuthenticated) {
      getAccountInfo();
    }
  }, [userStore.isAuthenticated]);

  const getAccountInfo = async () => {
    try {
      const response = await accountStore.getAccount();
      accountStore.setAccount(response);
    } catch (e) {
      toast.warning('Error while getting account information');
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setHovered(null);
      setVideoVisible({ left: false, right: false });

      Object.values(videoRefs).forEach((videoRef) => {
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      });
    }

    return () => {
      Object.values(videoRefs).forEach((videoRef) => {
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    setShowSelection(location.pathname === '/');
  }, [location.pathname]);

  const handleHover = (side: 'left' | 'right') => {
    if (!showSelection) return;

    if (isMobile) {
      if (hovered === side) return;
    }

    setHovered(side);
    setVideoVisible((prev) => ({ ...prev, [side]: true }));

    const video = videoRefs[side].current;
    if (video && video.readyState >= 3 && video.paused) {
      video.play().catch(() => {});
    }
  };

  const handleVideoClick = (side: 'left' | 'right') => {
    if (!isMobile || !showSelection) return;

    if (hovered === side) {
      // Reset to poster state
      setHovered(null);
      setVideoVisible((prev) => ({ ...prev, [side]: false }));

      const video = videoRefs[side].current;
      if (video && !video.paused) {
        video.pause();
        video.currentTime = 0;
      }
    } else {
      handleHover(side);
    }
  };

  const handleLeave = (side: 'left' | 'right') => {
    if (!showSelection) return;
    setHovered(null);

    setTimeout(() => {
      setVideoVisible((prev) => ({ ...prev, [side]: false }));

      const video = videoRefs[side].current;
      if (video && !video.paused) {
        video.pause();
      }
    }, 400);
  };

  useEffect(() => {
    return () => {
      Object.values(videoRefs).forEach((videoRef) => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      });
    };
  }, []);

  const handleSelectVariant = (side: 'left' | 'right') => {
    const newVariant = side === 'left' ? SiteVariant.SOMNIUM : SiteVariant.VR;

    userStore.setSiteVariant(newVariant);
    setShowSelection(false);
  };

  return (
    <div className={styles.pageWrapper}>
      <Header />

      <div className={cn(styles.container, { [styles.scrollable]: !showSelection })}>
        {showSelection ? (
          <div
            className={cn(styles.splitScreen, {
              [styles.hoveredLeft]: hovered === 'left',
              [styles.hoveredRight]: hovered === 'right',
            })}
          >
            <img
              src={welcomeBanner}
              alt="Welcome Banner"
              className={cn(styles.welcomeBanner, {
                [styles.hidden]: hovered !== null,
              })}
            />

            <div
              className={cn(styles.section, styles.left, {
                [styles.active]: hovered === 'left',
                [styles.inactive]: hovered === 'right',
              })}
              onMouseEnter={() => handleHover('left')}
              onMouseLeave={() => handleLeave('left')}
            >
              <img
                src={somniumPoster}
                alt="Somnium Space"
                className={cn(styles.thumbnail, { [styles.hidden]: videoVisible.left })}
              />

              <video
                ref={videoRefs.left}
                src={mainVR}
                loop
                muted
                playsInline
                crossOrigin="anonymous"
                className={cn(styles.video, { [styles.visible]: videoVisible.left })}
                onClick={() => handleVideoClick('left')}
              />

              <img src={somniumBanner} alt="Somnium Banner" className={styles.bannerLeft} />

              <div
                className={cn(styles.hoverButtonLeft, {
                  [styles.visible]: isMobile || hovered === 'left',
                })}
              >
                <div className={styles.rowLeft}>
                  <ButtonMUI
                    styleType="blue"
                    type="button"
                    className={styles.button}
                    onClick={() => handleSelectVariant('left')}
                    sx={{
                      '&&': {
                        padding: '5px 15px',
                        fontSize: '1.25rem',
                        height: '50px',
                        width: '305px',
                        background: 'rgba(25, 80, 125, 0.75)',
                        cursor: 'pointer !important',
                        opacity: isMobile || hovered === 'left' ? 1 : 0,
                        visibility: isMobile || hovered === 'left' ? 'visible' : 'hidden',
                        transition: 'opacity 0.4s ease-in-out, visibility 0.4s ease-in-out',
                      },
                    }}
                  >
                    Enter the Metaverse
                  </ButtonMUI>
                </div>
              </div>

              <div className={cn(styles.hoverContentLeft, { [styles.visible]: hovered === 'left' })}>
                <h2 className={styles.title}>Somnium Space VR Platform</h2>
                <p className={styles.subtitle}>
                  A persistent, open, and immersive Metaverse where you own, create, and explore.
                </p>
              </div>

              {isMobile && (
                <div className={styles.mobileRow}>
                  <img src={somniumBanner} alt="Somnium Banner" className={styles.mobileBanner} />
                  <ButtonMUI
                    styleType="blue"
                    type="button"
                    className={styles.button}
                    onClick={() => handleSelectVariant('left')}
                    sx={{
                      '&&': {
                        padding: '5px 15px',
                        fontSize: '0.8rem',
                        height: '35px',
                        width: '230px',
                        background: 'rgba(25, 80, 125, 0.75)',
                        marginLeft: '10px',
                      },
                    }}
                  >
                    Enter the Metaverse
                  </ButtonMUI>
                </div>
              )}
            </div>

            <div
              className={cn(styles.section, styles.right, {
                [styles.active]: hovered === 'right',
                [styles.inactive]: hovered === 'left',
              })}
              onMouseEnter={() => handleHover('right')}
              onMouseLeave={() => handleLeave('right')}
            >
              <img
                src={vrPoster}
                alt="VR Experience"
                className={cn(styles.thumbnail, { [styles.hidden]: videoVisible.right })}
              />

              <video
                ref={videoRefs.right}
                src={mainHeadset}
                loop
                muted
                playsInline
                crossOrigin="anonymous"
                className={cn(styles.video, { [styles.visible]: videoVisible.right })}
                onClick={() => handleVideoClick('right')}
              />

              <img src={vrBanner} alt="VR Banner" className={styles.bannerRight} />

              <div
                className={cn(styles.hoverButtonRight, {
                  [styles.visible]: isMobile || hovered === 'right',
                })}
              >
                <div className={styles.rowRight}>
                  <ButtonMUI
                    styleType="blue"
                    type="button"
                    className={styles.button}
                    onClick={() => handleSelectVariant('right')}
                    sx={{
                      '&&': {
                        padding: '5px 15px',
                        fontSize: '1.25rem',
                        height: '50px',
                        width: '305px',
                        background: 'rgba(25, 80, 125, 0.75)',
                        cursor: 'pointer !important',
                        opacity: isMobile || hovered === 'right' ? 1 : 0,
                        visibility: isMobile || hovered === 'right' ? 'visible' : 'hidden',
                        transition: 'opacity 0.4s ease-in-out, visibility 0.4s ease-in-out',
                      },
                    }}
                  >
                    Discover VR1
                  </ButtonMUI>
                </div>
              </div>

              <div className={cn(styles.hoverContentRight, { [styles.visible]: hovered === 'right' })}>
                <div className={styles.contentRow}>
                  <div className={styles.textContainer}>
                    <h2 className={styles.title}>The Somnium VR1 PCVR Headset</h2>
                    <p className={styles.subtitle}>
                      The most open, modular, versatile, and independent headset on the market.
                    </p>
                  </div>
                  <img src={madeInIcon} alt="Made In" className={styles.madeInIcon} />
                </div>
              </div>

              {isMobile && (
                <div className={styles.mobileRow}>
                  <ButtonMUI
                    styleType="blue"
                    type="button"
                    className={styles.button}
                    onClick={() => handleSelectVariant('right')}
                    sx={{
                      '&&': {
                        padding: '5px 15px',
                        fontSize: '0.8rem',
                        height: '35px',
                        width: '230px',
                        background: 'rgba(25, 80, 125, 0.75)',
                        marginRight: '10px',
                      },
                    }}
                  >
                    Discover VR1
                  </ButtonMUI>
                  <img src={vrBanner} alt="VR Banner" className={styles.mobileBanner} />
                </div>
              )}
            </div>
          </div>
        ) : (
          children
        )}
      </div>

      <Footer />
    </div>
  );
});
